import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "in_detail_box"
}
const _hoisted_2 = { class: "in_detail_box_input" }
const _hoisted_3 = { class: "in_detail_box_machines" }
const _hoisted_4 = { class: "machine_title" }
const _hoisted_5 = { class: "machine_items" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "item_index" }
const _hoisted_8 = { class: "item_num" }
const _hoisted_9 = { class: "in_detail_box_list" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 1,
  class: "in_detail_box"
}
const _hoisted_12 = { class: "bill_info" }
const _hoisted_13 = { class: "in_detail_box_machines" }
const _hoisted_14 = { class: "machine_title" }
const _hoisted_15 = { class: "machine_items" }
const _hoisted_16 = { class: "item_index" }
const _hoisted_17 = { class: "item_num" }
const _hoisted_18 = { class: "in_detail_box_list" }
const _hoisted_19 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_m_form = _resolveComponent("m-form")!
  const _component_m_table = _resolveComponent("m-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      title: _ctx.billTitle,
      "confirm-loading": _ctx.billLoading,
      cancelText: "关闭",
      okText: "确定",
      onOk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showBillDialog = false)),
      visible: _ctx.showBillDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showBillDialog) = $event))
    }, null, 8, ["title", "confirm-loading", "visible"]),
    _createVNode(_component_a_modal, {
      title: _ctx.detailTitle,
      "confirm-loading": _ctx.detailLoading,
      cancelText: _ctx.cancelText,
      okText: _ctx.isCreate ? _ctx.confirmText : "确定",
      onOk: $setup.machineHandle,
      visible: _ctx.showDetailDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showDetailDialog) = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.isCreate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _cache[15] || (_cache[15] = _createElementVNode("p", null, "请仔细填写以下信息", -1)),
              _createElementVNode("div", _hoisted_2, [
                _cache[10] || (_cache[10] = _createElementVNode("label", { for: "" }, "新增设备: ", -1)),
                _createVNode(_component_a_input, {
                  value: _ctx.machineId,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.machineId) = $event)),
                  placeholder: "请输入设备编号"
                }, null, 8, ["value"]),
                _createVNode(_component_a_button, {
                  disabled: _ctx.detailDisable,
                  type: "primary",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.inStock(_ctx.machineId)))
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("添加")
                  ])),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _cache[13] || (_cache[13] = _createElementVNode("h3", null, "设备列表", -1)),
                  _createElementVNode("p", null, [
                    _cache[11] || (_cache[11] = _createTextVNode("共")),
                    _createElementVNode("span", null, _toDisplayString(_ctx.machineList.length), 1),
                    _cache[12] || (_cache[12] = _createTextVNode("台")),
                    _createElementVNode("span", {
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.deleteMachine && $setup.deleteMachine(...args)))
                    }, "删除")
                  ])
                ]),
                _createElementVNode("ul", _hoisted_5, [
                  _cache[14] || (_cache[14] = _createElementVNode("div", null, [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", null, "序号"),
                      _createElementVNode("span", null, "设备号")
                    ]),
                    _createElementVNode("p", null, [
                      _createElementVNode("span", null, "序号"),
                      _createElementVNode("span", null, "设备号")
                    ])
                  ], -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.machineList, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: item+''+index
                    }, [
                      _createElementVNode("span", {
                        onClick: ($event: any) => ($setup.chooseMachine(item)),
                        class: _normalizeClass(item.isChoosed ? 'item_check item_check_active' : 'item_check')
                      }, null, 10, _hoisted_6),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(index+1), 1),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(item.num), 1)
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("ul", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailList, (detailItem, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: detailItem.id+index
                  }, [
                    _createElementVNode("p", null, _toDisplayString(detailItem.deviceNo), 1),
                    _createElementVNode("span", {
                      onClick: ($event: any) => (_ctx.cancelInStorage(detailItem.deviceNo))
                    }, "—", 8, _hoisted_10)
                  ]))
                }), 128))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("p", null, [
                  _cache[16] || (_cache[16] = _createElementVNode("span", null, "入库单ID：", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.detailInfo.orderId), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[17] || (_cache[17] = _createElementVNode("span", null, "单据创建时间：", -1)),
                  _createElementVNode("span", null, _toDisplayString($setup.formatTime(_ctx.detailInfo.createTime)), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[18] || (_cache[18] = _createElementVNode("span", null, "单据状态：", -1)),
                  _createElementVNode("span", null, _toDisplayString($setup.getMapValue(_ctx.detailInfo.status)), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[19] || (_cache[19] = _createElementVNode("span", null, "机器数量：", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.detailInfo.number), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[20] || (_cache[20] = _createElementVNode("span", null, "操作员ID：", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.detailInfo.sysUserId), 1)
                ]),
                _createElementVNode("p", null, [
                  _cache[21] || (_cache[21] = _createElementVNode("span", null, "操作员姓名：", -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.detailInfo.sysUserName), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _cache[24] || (_cache[24] = _createElementVNode("h3", null, "设备列表", -1)),
                  _createElementVNode("p", null, [
                    _cache[22] || (_cache[22] = _createTextVNode("共")),
                    _createElementVNode("span", null, _toDisplayString(_ctx.machineList.length), 1),
                    _cache[23] || (_cache[23] = _createTextVNode("台"))
                  ])
                ]),
                _createElementVNode("ul", _hoisted_15, [
                  _cache[25] || (_cache[25] = _createElementVNode("div", null, [
                    _createElementVNode("p", null, [
                      _createElementVNode("span", null, "序号"),
                      _createElementVNode("span", null, "设备号")
                    ]),
                    _createElementVNode("p", null, [
                      _createElementVNode("span", null, "序号"),
                      _createElementVNode("span", null, "设备号")
                    ])
                  ], -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.machineList, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: item+''+index
                    }, [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(index+1), 1),
                      _createElementVNode("span", _hoisted_17, _toDisplayString(item.num), 1)
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("ul", _hoisted_18, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.detailList, (detailItem, index) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: detailItem.id+index
                  }, [
                    _createElementVNode("p", null, _toDisplayString(detailItem.deviceNo), 1),
                    _createElementVNode("span", {
                      onClick: ($event: any) => (_ctx.cancelInStorage(detailItem.deviceNo))
                    }, "—", 8, _hoisted_19)
                  ]))
                }), 128))
              ])
            ]))
      ]),
      _: 1
    }, 8, ["title", "confirm-loading", "cancelText", "okText", "onOk", "visible"]),
    _createVNode(_component_m_form, {
      onLoadData: _cache[6] || (_cache[6] = ($event: any) => ($setup.loadData(1))),
      onAddNew: $setup.addNew,
      formItems: _ctx.formItems
    }, null, 8, ["onAddNew", "formItems"]),
    _createVNode(_component_m_table, {
      columns: _ctx.columns,
      data: _ctx.tableList,
      buttons: _ctx.buttons,
      loading: _ctx.loading,
      totals: _ctx.total,
      currentPage: _ctx.currentPage,
      onPageChange: _cache[7] || (_cache[7] = ($event: any) => ($setup.loadData($event))),
      onInDetail: _cache[8] || (_cache[8] = ($event: any) => ($setup.inDetail($event)))
    }, null, 8, ["columns", "data", "buttons", "loading", "totals", "currentPage"])
  ]))
}